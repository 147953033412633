import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { PageSeo } from "../components/PageSeo"
import Layout from "../components/Layout"
import ReactMarkdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"
import "./styles/offerings.scss"

const ServicesSlug = ({ data }) => {
  const { markdownRemark: markup } = data
  const { name, description, banner, content, blurb } = markup.frontmatter

  return (
    <Layout>
      <section className="Servicesslug">
        <div className="container text-center py-5 animate__animated animate__fadeIn">
          <div className="row">
            <h2 className="section_heading">{name}</h2>
            <div className="col-12 col-lg-8 mx-auto">
              <ReactMarkdown children={description} />
            </div>
            <div className="col-12 col-lg-10 mx-auto animate__animated animate__fadeInUp">
              <ReactMarkdown children={content} />
            </div>
          </div>
        </div>

        <div className="banner animate__animated">
          <GatsbyImage
            image={banner.image.childImageSharp.gatsbyImageData}
            alt={name}
            className="bannerImage py-3 py-lg-5"
          />
        </div>

        <Features blurb={blurb} />
      </section>
    </Layout>
  )
}
ServicesSlug.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

const Features = ({ blurb }) => {
  return (
    <div className="py-3 mb-5">
      <div className="container">
        <h3 className="section_heading">Features</h3>
        <div className="row mt-3">
          <div className="col-12 col-lg-3 d-none d-lg-block">
            <div className="nav flex-column nav-pills tab-vertical-custom me-3">
              {blurb.length &&
                blurb.map((item, index) => (
                  <button
                    className={`nav-link rounded-0 btn-default d-block text-start ${
                      index === 0 ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    data-bs-target={`#blurb-content_${index}`}
                  >
                    {item.title}
                  </button>
                ))}
            </div>
          </div>
          <div className="col-12 col-lg-9">
            <div className="tab-content mb-3 mb-lg-0">
              {blurb.length &&
                blurb.map((item, index) => (
                  <div
                    className={`tab-pane ${index === 0 ? "active" : ""}`}
                    id={`blurb-content_${index}`}
                    key={`blurb_${index}`}
                  >
                    <div className="row">
                      <div className="col-12 col-lg-8">
                        <h4>{item.title}</h4>
                        <p className="my-4">{item.content}</p>
                      </div>
                      <div className="col-12 col-lg-4" key={`blurb_${index}`}>
                        <GatsbyImage
                          image={item.image.childImageSharp.gatsbyImageData}
                          alt={item.title}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
Features.propTypes = {
  blurb: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
}

export const servicesQuery = graphql`
  query servicesQueryByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        seoContent {
          description
          keywords
          title
        }
        name
        description
        content
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        banner {
          heading
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        blurb {
          title
          content
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
export default ServicesSlug

export const Head = ({ data }) => (
  <PageSeo
    title={
      data.markdownRemark.frontmatter.seoContent.title ||
      data.markdownRemark.frontmatter.name
    }
    description={
      data.markdownRemark.frontmatter.seoContent.description ||
      data.markdownRemark.frontmatter.name
    }
    keywords={
      data.markdownRemark.frontmatter.seoContent.keywords ||
      data.markdownRemark.frontmatter.name
    }
  />
)
